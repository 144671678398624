<template>
<div class="modal">
  <div class="modal__overlay"></div>
</div>
</template>

<script setup lang="ts">
import { useLoadingStore } from '~/store/loading';

const loadingStore = useLoadingStore();
</script>

<style scoped lang="scss">

</style>
